import './Contact.scss'

function About() {
    return (
        <div>
            <section className="home-banner-area">
                <div className="container">
                    <div className="row fullscreen d-flex align-items-center justify-content-between">
                        <div style={{ display: "flex", flexDirection: "row", background: "white", width: "70vw", borderRadius: "5px", margin: "auto", padding: "5%", boxShadow: "#cccccc 1px 8px 27px 1px" }}>
                            <div >
                                <img className="img-fluid" src="https://res.cloudinary.com/siimpl/image/upload/v1643246941/coffee.jpg" alt="" />
                            </div>
                            <div className='m-auto ps-5'>
                                <p style={{ fontSize: "20px", margin: "auto" }}>
                                    LOCALE is a technology company focused on creating and empowering a community of small coffee shops and their patrons. LOCALE provides users with a loyalty program and in-app features to connect coffee shops to coffee enthusiasts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default About;
