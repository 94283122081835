import { useContext, useEffect, useState } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import Rodal from 'rodal';
import { fetchAPI } from '../helpers/FetchAPI';
import { DataContext } from '../Providers/DataProvider';

// const users = [
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Garry', email: 'garridio85@hotmail.co.uk', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
//   { Id: 0, displayName: 'Adam', email: 'adam.levine@gmail.com', image: '', device: 'iPhone 13', OS: 'IOS', dateJoined: new Date(), lastLogin: new Date() },
  
// ]

const userPointHistory = [
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },
  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '10', image: '', pointType: 'Earned', date: new Date() },

  { Id: 0, merchant: 'Sam James Coffee Bar', area: 'Toronto Street', points: '-70', image: '', pointType: 'Redeemed', date: new Date() },

]

const UserTable = () => {
  const { users, setUsers } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [seePointHistoryModal, setSeePointHistoryModal] = useState(false);  
  const [searchQuery, setSearchQuery] = useState('');  

  useEffect(() => {
    getUsers();
  }, [])
  
  const getUsers = () => {
    if (users.length > 0) {
      setUsers(users);
      setIsLoading(false);
    } else {
      fetchAPI.get('/api/users')
        .then(users => {
          setUsers(users);
          setIsLoading(false);
        }).catch(err => {
          console.log(err);
        });
    }
  };
  
  const filteredUsers = users.filter(el => el.DisplayName.toLowerCase().includes(searchQuery.toLowerCase()) || el.UserEmail.toLowerCase().includes(searchQuery.toLowerCase()));

  if (isLoading) {
    return (
      <div>Loading...</div>
    );
  };

  return (
      <div>
        <input placeholder="Search users..." type="text" onChange={(e) => setSearchQuery(e.target.value)} />          

        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th></th>
              <th>Name</th>
              <th>Email</th>
              <th>Device</th>
              <th>OS</th>
              <th>Date Joined</th>
              <th>Last Login</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {filteredUsers.map((item, index) =>
            <tr>
              <td>{item.Id}</td>
              <td>
                <div style={{ width: '40px', height: '40px', borderRadius: '40px', overflow: 'hidden' }}>
                  <img src={'https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortRound&accessoriesType=Sunglasses&hairColor=BrownDark&facialHairType=MoustacheFancy&facialHairColor=BrownDark&clotheType=ShirtVNeck&clotheColor=Gray01&eyeType=Surprised&eyebrowType=RaisedExcitedNatural&mouthType=Eating&skinColor=Pale'} alt='merchant storefront' style={{ objectFit: 'cover', width: '100%', height: '100%' }}/>
                </div>
              </td>
              <td>{item.DisplayName}</td>
              <td>{item.UserEmail}</td>
              <td>{item.device}</td>
              <td>{item.OS}</td>
              <td>{new Date(item.DateCreated).toDateString()}</td>
              <td>{new Date(item.LastLoginTimestamp).toDateString()}</td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="link" className="dropdown-basic">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item >Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => setSeePointHistoryModal(true)}>See point history</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </td>
            </tr>
          )}
          </tbody>
      </Table>
      
      <Rodal visible={seePointHistoryModal} onClose={() => setSeePointHistoryModal(false)}>
        {seePointHistoryModal && (
          <div className='user-point-history'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th></th>
                    <th>Merchant</th>
                    <th>Location</th>
                    <th>Points</th>
                    <th>Point Type</th>
                    <th>Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {userPointHistory.map((item, index) =>
                  <tr>
                    <td>{item.Id}</td>
                    <td>
                      <div style={{ width: '40px', height: '40px', borderRadius: '40px', overflow: 'hidden' }}>
                        <img src={item.image} alt='merchant storefront' style={{ objectFit: 'cover', width: '100%', height: '100%' }}/>
                      </div>
                    </td>
                    <td>{item.merchant}</td>
                    <td>{item.area}</td>
                    <td>{item.points}</td>
                    <td>{item.pointType}</td>
                    <td>{item.date.toDateString()}</td>
                    <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="link" className="dropdown-basic">
                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                          <Dropdown.Item >Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => setSeePointHistoryModal(true)}>See point history</Dropdown.Item>
                        {/* <Dropdown.Item onClick={() => setEditModal(true)}>Show Preview</Dropdown.Item> */}
                        {/* <Dropdown.Item onClick={() => setEditModal(true)}>Something else</Dropdown.Item> */}
                      </Dropdown.Menu>
                    </Dropdown>
                    </td>
                  </tr>
                )}
                </tbody>
            </Table>  
          </div>
        )}
    </Rodal>
      </div>
  )
}

export { UserTable }