import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import Rodal from 'rodal';

import { fetchAPI } from '../helpers/FetchAPI';

import './LocaleEditor.scss';
import LocaleFeatures from './LocaleFeatures';
import LocaleHours from './LocaleHours';

const hourData = [
    { day: 'Sunday', open: '08:00', close: '17:00' },
    { day: 'Monday', open: '08:00', close: '17:00'  },
    { day: 'Tuesday', open: '08:00', close: '17:00'  },
    { day: 'Wednesday', open: '08:00', close: '17:00'  },
    { day: 'Thursday', open: '08:00', close: '17:00'  },
    { day: 'Friday', open: '08:00', close: '17:00'  },
    { day: 'Saturday', open: '08:00', close: '17:00'  },
]
    
export const LocaleEditor = ({ location, close }) => {
    const [featuresModal, setFeaturesModal] = useState(false);
    const [hours, setHours] = useState(JSON.parse(location.hours) || hourData);
    const [name, setName] = useState(location.name)
    const [price, setPrice] = useState(location.price)
    const [description, setDescription] = useState(location.description)
    const [hasParking, setHasParking] = useState(location.has_parking)
    const [phone, setPhone] = useState(location.phone)
    const [isPermanentlyClosed, setIsPermanentlyClosed] = useState(location.is_permanently_closed)

    const updateLocation = () => {

        const data = {
            Id: location.Id,
            name: name,
            price: price,
            phone: phone,
            description: description,
            has_parking: hasParking,
            is_permanently_closed: isPermanentlyClosed,
            hours: JSON.stringify(hours)
        }
        
        fetchAPI.post('/api/updateLocation', {
            body: data
        })
			.then(() => {
                location.name = name;
                location.price = price;
                location.description = description;
                location.has_parking = hasParking;
                location.phone = phone;
                location.is_permanently_closed = isPermanentlyClosed;
                location.hours = JSON.stringify(hours)
                close();
			}).catch(err => {
				console.log(err);
			});
    }
    
    return (
        <div className='locale-editor'>
            <div className='d-flex locale-editor-container'>
                <div className='w-50 pe-5'>
                    <h5>Name</h5>
                    <input placeholder='name...' value={name} onChange={(e) => setName(e.target.value)} />

                    <h5>Description</h5>
                    <textarea placeholder='name...' value={description}  onChange={(e) => setDescription(e.target.value)}>
                    
                    </textarea>

                    <h5>Phone</h5>
                    <input placeholder='name...' value={phone} onChange={(e) => setPhone(e.target.value)} />
                    
                    <h5>Whats available</h5>
                    <Button variant='link' className='p-0 my-2' onClick={() => setFeaturesModal(true)}>set whats available</Button>

                    <h5>Price</h5>
                    <div className='d-flex price-container'>
                        <label className='d-flex flex-column mr-4'>
                            <span>$</span>
                            <input type='radio' checked={price === '$'} onClick={() => setPrice('$')} />
                        </label>

                        <label className='d-flex flex-column mr-4'>
                            <span>$$</span>
                            <input type='radio' checked={price === '$$'} onClick={() => setPrice('$$')} />
                        </label>

                        <label className='d-flex flex-column mr-4'>
                            <span>$$$</span>
                            <input type='radio' checked={price === '$$$'} onClick={() => setPrice('$$$')} />
                        </label>
                    </div>
                </div>
                <div className='w-50 pe-5'>
                    <h5>Parking</h5>
                    <label>
                        <input type='checkbox' checked={hasParking} onClick={() => setHasParking(!hasParking)} /> 
                        Has Parking           
                    </label>

                    <h5>Hours</h5>
                    <LocaleHours hours={hours} setHours={setHours} />

                    <h5>Is Permanently Closed</h5>
                    <label>
                        <input type='checkbox' checked={isPermanentlyClosed} onClick={() => setIsPermanentlyClosed(!isPermanentlyClosed)} />
                        Is Permanently Closed  
                    </label>
                </div> 
            </div>
              
            <div>
                <button onClick={() => updateLocation()}>Save</button>
            </div>

            <Rodal visible={featuresModal} onClose={() => setFeaturesModal(false)}>
                {featuresModal && (
                    <LocaleFeatures locationId={location.Id} close={() => setFeaturesModal(false)} />
                )}
            </Rodal> 

        </div>
    )
}