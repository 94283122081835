import { useContext } from "react";
import { signOut } from './Auth/firebase';
import {
    BrowserRouter,
    Routes,
    Route,
    Link
  } from "react-router-dom";
import { UserContext } from "./Auth/UserProvider";
import DataProvider from './Providers/DataProvider';
import Locale from "./Website/Locale";

import Admin from './Pages/Admin';
import Login from './Auth/Login';
import Users from './Pages/Users';
import Points from './Pages/Points';
import Preview from './Pages/Preview';

const Pages = (): JSX.Element => {
    const { isLoggedIn, userDetails } = useContext(UserContext);

    return (
        <BrowserRouter>
            {(isLoggedIn && userDetails) ? (
                <DataProvider>
                    <div className='locale-wrapper'>
                        <div className='side-bar-container'>
                            <div className='logo'>
                                <h2>LOCALE</h2>
                            </div>
                            <div className="sidebar-wrapper">
                                <Link className="admin-link" to="/Dashboard">Dashboard</Link>
                                <Link className="admin-link" to="/Merchants">Merchants</Link>
                                <Link className="admin-link" to="/Users">Users</Link>
                                <Link className="admin-link" to="/Points">Points</Link>
                            </div>
                            
                        </div>
                        <div className='page-container'>
                            <div className='header'>
                                <button onClick={() => signOut() } className="log-out-btn" ><small>Log Out</small></button>
                            </div>
                            <div className='page-wrapper'>
                                <Routes>
                                    <Route path="/" element={<Admin />} />
                                    {(userDetails.uid === 'gTiIRDENdgdgv6RbmX9xgP11rbl1') ? (
                                        <Route path="/admin" element={<Admin />} />
                                    ) : (
                                        <Route path="/admin" element={<Locale />} />
                                    )}
                                    <Route path="/Merchants" element={<Admin />} />
                                    <Route path='/Users' element={<Users />} />
                                    <Route path='/Points' element={<Points />} />
                                </Routes>
                            </div>
                        </div>
                    </div>
                </DataProvider>          
            ) : (
                <Locale />
            )}
            
        </BrowserRouter>
    );
}

export default Pages;