import { useState } from 'react';
import './JoinUs.scss';
import './Contact.scss';

import coffeeGif from '../assets/coffeeGif.gif';

function Contact() {
    const [coffeeShopName, setCoffeeShopName] = useState('');
    const [coffeeShopAddress, setCoffeeShopAddress] = useState('');
    const [name, setName] = useState('');
    const [position, setPosition] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [confirmation, setConfirmation] = useState(null);

    const confirmationError = confirmation && confirmation.includes('error');
    const disabled = !coffeeShopName || !coffeeShopAddress || !name || !position || !email;

    const handleSubmit = (e) => {
        e.preventDefault();

        const emailBody = {
            coffeeShopName,
            coffeeShopAddress,
            name,
            position,
            email,
            comment
        }
        
        fetch('/public/join/claimLocationv1', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(emailBody)
        })
            .then(() => {
                setConfirmation('We have received your request and will be in touch soon!')
                window.scrollTo(0,0)
            })
            .catch(() => {
                setConfirmation('There was an error submitting your request. Please try again later or email info@localeapp.co')
            })
    }

    return (
        <section className="home-banner-area">
            <div className="container">
                <div id="join-us" className="row d-flex align-items-center justify-content-between">
                    <div style={{ maxHeight: "unset", marginTop: 150, marginBottom: 100 }}>
                        {confirmation ?
                            <div id="contact-form" className='mt-5'>
                                <div style={{ maxWidth: "500px", margin: "auto", textAlign: "center" }}>
                                    <img src={coffeeGif} alt="funny gif showing jim carrey drink coffee" style={{ width: "100%" }} />
                                </div>
                                <h3 className="text-center">{confirmation}</h3>
                            </div>
                            :
                            <>
                                <div style={{ width: "75%", margin: "auto"}}>
                                    <h1 className="m-0 p-0">Are you interested in joining Locale?</h1>
                                    <h5 className="m-0 p-0">Fill out the form below and be the first to be notified when we go into beta.</h5>
                                </div>
                                <form id="contact-form" className='mt-5' style={{ maxHeight: "unset" }}  onSubmit={(e) => handleSubmit(e)} method="POST">
                                    <div className="form-group">
                                        <label htmlFor="name">Coffee Shop Name</label>
                                        <input type="text" onChange={(e) => setCoffeeShopName(e.target.value)} value={coffeeShopName} className="form-control" />
                                    </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="name">Coffee Shop Address</label>
                                        <input type="text" onChange={(e) => setCoffeeShopAddress(e.target.value)} value={coffeeShopAddress} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Name</label>
                                        <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Company Position</label>
                                        <input type="text" onChange={(e) => setPosition(e.target.value)} value={position} className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Email address</label>
                                        <input type="email" onChange={(e) => setEmail(e.target.value)} value={email} className="form-control" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">Comment</label>
                                        <textarea className="form-control" onChange={(e) => setComment(e.target.value)} value={comment} rows="3"></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary" disabled={disabled}>Submit</button>
                                </form>
                            </>
                        }
                      
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
