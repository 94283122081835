import React, { useState, createContext, useEffect, useMemo, ReactElement } from "react";
import { auth, getUserDocument, User } from "./firebase";

const EmptyUser: User = {
  uid: '',
  displayName: '',
  isFirstLogin: false,
  photoURL: '',
}

type AuthType = {
  isLoading: boolean,
  isLoggedIn: boolean,
  userDetails: User
}

const AuthState = {
  isLoading: true,
  isLoggedIn: false,
  userDetails: EmptyUser
}

export const UserContext = createContext<AuthType>(AuthState);

export default function UserProvider({children} : {children: React.ReactNode}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>(EmptyUser);

  useEffect(() => {
    auth.onAuthStateChanged(async userAuth => {
      if (!userAuth) {
        setIsLoading(false);
        return;
      }

      const uid = userAuth.uid.toString();
      const user = await getUserDocument(uid);
      if (user) {
        let localUser: User = {
          displayName: user.displayName,
          isFirstLogin: user.isFirstLogin,
          photoURL: user.photoURL,
          uid: user.uid,
        }

        setUserDetails(localUser);
        setIsLoggedIn(true)
        localStorage.setItem("user", JSON.stringify(localUser));
      }       

      setIsLoading(false);
    });
  }, [])

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <UserContext.Provider value={{ isLoading, isLoggedIn, userDetails }}>
      {children}
    </UserContext.Provider>
  )
}