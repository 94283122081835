import { UserTable } from "../Components/UserTable";

import './Users.scss';

const Users = () => {
    return (
        <div className='users'>
            <div className='user-select-container'></div>
            <div className='user-container'>
                <UserTable />
            </div>
        </div>
    )
}

export default Users;