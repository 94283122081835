/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */
import firebase from "firebase/app"
import "firebase/auth"

const BASE_URL = 'https://localeapp.co'; // 'http://localhost:3008';

export async function getToken() {
	const { currentUser } = firebase.auth();
	return currentUser.getIdToken(true)
		.then((idToken) => idToken).catch((error) => {
			console.log(error);
		});
}

async function apiOptions(method, options) {
	const accessToken = await getToken();
	if (!options) {
		options = {};
	}

	if (!options.headers) {
		options.headers = {};
	}
	options.headers.Authorization = `Bearer ${accessToken}`;

	const optionResponse = {
		method,
		headers: options.headers,
	};

	if (method === 'POST') {
		options.headers['Content-Type'] = 'application/json';
		optionResponse.body = JSON.stringify(options.body);
	}

	return optionResponse;
}

export const fetchAPI = {
	get,
	post,
};

async function get(url, options) {
	const requestOptions = await apiOptions('GET', options);
	return fetch(BASE_URL + url, requestOptions).then(handleResponse);
}

async function post(url, options) {
	const requestOptions = await apiOptions('POST', options);
	return fetch(BASE_URL + url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
	if (response.status !== 200) {
		console.log('error');
	}
	return response.json();
}
