import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Rodal from 'rodal';

import './LocaleHours.scss';



export default function LocaleHours({ hours, setHours}) {
    const [hourModal, setHourModal] = useState(false);
    
    const [selectedHours, setSelectedHours] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    
    const getHours = (item) => {
        setHourModal(true);
        setSelectedHours(item);
        setStartTime(item.open);
        setEndTime(item.close);
    }

    const updateHours = (selectedHours) => {
        selectedHours.open = startTime;
        selectedHours.close = endTime;
        setHours(prevState => prevState.map(el => el.day === selectedHours.day ? { ...el, selectedHours } : el));
        setHourModal(false);
    }

    return (
        <div className='hours-container'>
            {hours.map((item, index) =>
                <div className='d-flex justify-content-between hour-item' onClick={() => getHours(item) }>
                    <div>{item.day}</div>
                    <div className='d-flex'>
                        <div>{item.open}-{item.close}</div>
                    </div>
                </div>
            )}

            <Rodal visible={hourModal} onClose={() => setHourModal(false)}>
                {hourModal && (
                    <div>
                        <h5 className='mb-3'>{selectedHours.day}</h5>
                        <div className='d-flex'>
                            <input type="time" className='me-4' defaultValue={startTime} onChange={(e) => setStartTime(e.target.value)} required />
                            <input type="time" defaultValue={endTime} onChange={(e) => setEndTime(e.target.value)} required />
                        </div>
                        <div className='text-end'>
                            <Button className='mt-2' onClick={() => updateHours(selectedHours)}>
                                Update
                            </Button>
                        </div>
                    </div>
                )}
            </Rodal>
        </div>
    )
}