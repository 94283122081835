import { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { auth, getUserDocument } from '../Auth/firebase';
import {
    Routes,
    Route,
	Link,
	useLocation,
} from "react-router-dom";
  
import Contact from './Contact';
import Login from '../Auth/Login';
import Home from './Home';
import Join from './JoinUs';
import About from './About';

import '../App.scss';
import logo from '../assets/logo.png';
import logoWhite from '../assets/logo_white.png';

function Locale() {
	const location = useLocation();
	const [user, setUser] = useState(null);
	const [mobNav, setMobNav] = useState(false);
	

	useEffect(() => {
		auth.onAuthStateChanged(async userAuth => {
			if (userAuth) {
				const user = await getUserDocument(userAuth.uid);
				setUser(user)
			}
		});
	}, [])

	return (
		<div className='locale-container'>
			<header id="header">	
				<div className="container main-menu">
					<div className="row align-items-center justify-content-between d-flex">		
						<div className='d-flex  justify-content-between'>
							<a href="/">
								{window.innerWidth < 600 ? 
									<img className='logo' src={logoWhite} style={{ marginLeft: "-20px" }} alt="" title="" />
									:
									<img className='logo' src={ logo} alt="" title="" />
								}								
							</a>
								
							{window.innerWidth < 600 ? (
								<Button className='transparent-button mt-2' variant="link" type="button" onClick={() => setMobNav(!mobNav)} id="mobile-nav-toggle">
									<svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
								</Button>
							) : (
								<div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
									<Link to="/" className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>Home</Link>
									<Link to="/About" className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>About</Link>
										{location.pathname === "/" && (
											<a href='/#video' className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>See it in action</a>
										)}
									<Link to="/Join" className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>Join Us</Link>
									<Link to="/Contact" className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>Contact Us</Link>
								</div>
							)}
						</div>							
					</div>
				</div>
			</header>

			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/login" element={<Login />} />
				<Route path="/Contact" element={<Contact />} />
				<Route path="/Join" element={<Join />} />
				<Route path="/About" element={<About />} />
			</Routes>

			<footer className="footer-area section-gap">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-6 single-footer-widget">
							<ul>
								<li><a href="#">Terms Of Service</a></li>
								<li><a href="#">Privacy Policy</a></li>
								<li><a href="#">Data Deletion</a></li>
								<li>
									{!user ? (
										<Link to="/login" className="nav-link m-0 p-0">Admin Login</Link>
									) : (
										<Link to="/admin" className="nav-link m-0 p-0">Admin</Link>
									)}
								</li>
							</ul>
						</div>
						<div className="col-lg-4 col-md-6 single-footer-widget">
							{/* <h4 className="m-0">Waitlist</h4>
							<p className=" m-0 text-light">Get notified when we go into beta:</p> */}
							{/* <Waitlist /> */}
						</div>
					</div>
				</div>
			</footer>

			<div style={{ backgroundColor: '#222222', width: '100%', color: 'white', fontSize: 17 }}>
				<p className="m-0 text-center">
					Copyright LocaleRewards &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved
				</p>
				<p className="m-0 mt-4 ml-2" style={{ fontSize: 10, marginLeft: 5 }}>
					This template is made with <i className="fa fa-heart-o" aria-hidden="true"></i> by <a href="https://colorlib.com" target="_blank">Colorlib</a>
				</p>
			</div>

			<MobileNav mobNav={mobNav} closeMenu={() => setMobNav(false)} location={location} />
			
		</div>
	);
}

export const Waitlist = () => (
<div id="mc_embed_signup">
	<form action="https://Localeapp.us20.list-manage.com/subscribe/post?u=3b15146086bc709684822c9bc&amp;id=9de4a3e0cb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate p-0" target="_blank" noValidate>
		<div id="mc_embed_signup_scroll">
		
			<div className="mc-field-group">
				<input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" style={{ height: '50px', outline: 'none !important' }} />
			</div>
			<div id="mce-responses" className="clear foot">
				<div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
				<div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
			</div>

			<div style={{ position: "absolute", left: "-5000px" }}>
				<input type="text" name="b_3b15146086bc709684822c9bc_9de4a3e0cb" tabIndex="-1" defaultValue="" />
			</div>
			<div className="optionalParent">
				<div className="clear foot">
					<input type="submit" defaultValue="Join waitlist" name="subscribe" id="mc-embedded-subscribe" className="button" style={{ height: '50px', background: '#07a1dd' }} />
				</div>
			</div>
		</div>
	</form>
</div>
)

export const MobileNav = ({ mobNav, closeMenu, location }) => {

	const clickOutside = () => {
		closeMenu();
	}

	return (
		<nav id="mobile-nav" className={mobNav ? 'active' : ''} >
			<ul className='mobile-navigation'>
				<li><Link to="/" onClick={() => clickOutside()} className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>Home</Link></li>
				<li><Link to="/About" onClick={() => clickOutside()} className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>About</Link></li>
				{location.pathname === "/" && (
					<li><a href='/#video' onClick={closeMenu} className="nav-link font-weight-bold mr-3 text-white" style={{ paddingTop: "15px" }}>See it in action</a></li>
				)}
				{/* <li><Link to="/#video" onClick={() => clickOutside()} className="nav-link font-weight-bold mr-3 text-white" style={{ paddingTop: "15px" }}>See it in action</Link></li> */}
				<li><Link to="/Join" onClick={() => clickOutside()} className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>Join Us</Link></li>
				<li><Link to="/Contact" onClick={() => clickOutside()} className="nav-link font-weight-bold mr-3  text-white" style={{ paddingTop: "15px" }}>Contact Us</Link></li>
			</ul>
			<div className='close' onClick={() => clickOutside()}>

			</div>
		</nav>
	)
}

export default Locale;
