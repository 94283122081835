import { useState } from 'react';
import playbtn from '../assets/play-btn-dark.png';

import HomeScreen from './Assets/Home.png';
import MapScreen from './Assets/Map.png';
import MessageScreen from './Assets/Message.png';
import OffersScreen from './Assets/Offers.png';

function Home() {    
    const [showVideo, setShowVideo] = useState(false);
    
    return (
        <div>
            <section className="home-banner-area">
                <div className="container">
                    <div className="row fullscreen d-flex align-items-center justify-content-between">
                        <div className="home-banner-content col-lg-6 col-md-6">
                            <h1>
                                A free loyalty program designed for local coffee shops
                            </h1>
                        </div>
                        <div className="banner-img col-lg-4 col-md-6 d-flex">
                            <img className="img-fluid mt-5" src={HomeScreen} alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="feature-area section-gap-top">
                <div className="feature-bg"></div>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title text-center">
                                <h2>Grow your business</h2>
                                <p>
                                    Grow your business by offering  an engaging loyalty program to your customers through a well-designed and easy-to-use App.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature">
                                <div className="title">
                                    <span className="lnr lnr-book"></span>
                                    <h3>Join a community</h3>
                                </div>
                                <p>
                                    Become part of a close knit community of local coffee shops that share in the advantages of a loyalty program
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature" >
                                <div className="title">
                                    <span className="lnr lnr-book"></span>
                                    <h3>Boost retention</h3>
                                </div>
                                <p>
                                    Whether they are first-time customers, regulars or general coffee enthusiasts in the area. Market to different types of customers using in-app features
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-feature">
                                <div className="title">
                                    <span className="lnr lnr-book"></span>
                                    <h3>Increase foot traffic</h3>
                                </div>
                                <p>
                                    Use push notifications, in-app messaging and personalized offers to bring new and regular customers into your shop.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            {/* <!-- Start about Area --> */}
            <section className="about-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 d-flex">
                            <img className="img-fluid m-auto app-screenshot" src={MapScreen} alt="" />
                        </div>
                        <div className="offset-lg-1 col-lg-5 home-about-right">
                            <h1>
                                Get discovered
                            </h1>
                            <p>
                                Coffee lovers prefer local coffee, but don’t like an endless search.
                                {/* Locale makes it easy for them to find their new favourite, independent coffee shop. */}
                                Locale makes it easy for them to find their new favourite, independant coffee shops.
                                {/* Loyalty programs are no longer just for the large coffee chains! LOCALE's rewards-based loyalty program promises to bring in more business . */}
                            </p>  
                            <p>
                                Users are able to search by menu item, intergrated map, ratings and promotional offers while coffee shops can customize their profile page to communicate uniqueness, promotions, menu items and more.
                            </p>
                        </div>
                    </div>
                    <div className="row align-items-center customer-loyalty">
                        <div className="offset-lg-1 col-lg-6 home-about-right">
                            <h1>
                                Increase customer loyalty
                            </h1>
                            <p>
                                Global Coffee chains have access to new technology and sophisticated loyalty programs, giving them a distinct advantage in customer retention and promotion.
                            </p>
                            <p>
                                By joining the Locale community, you can continue to build on your unique brand with all the benefits of a loyalty program and a well-designed app.    
                            </p>
                            <p>
                                In a world full of global coffee chains with huge loyalty programs it is more important than ever to group together.
                            </p>
                            {/* <AppStoreLinks /> */}
                            {/* <Waitlist /> */}
                        </div>
                        <div className="col-lg-5 d-flex home-about-left">
                            <img className="img-fluid m-auto app-screenshot" src={OffersScreen} alt="" />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-5 d-flex">
                            <img className="img-fluid m-auto app-screenshot" src={MessageScreen} alt="" />
                        </div>
                        <div className="offset-lg-1 col-lg-5 home-about-right">
                            <h1>
                                Direct Communication
                            </h1>                            	
                            <p>
                                Make your customers feel special by rewarding them with a free coffee on their birthday, "First time visit anniversary," or other special occasions.                                                                                                     
                            </p>
                            <p>
                                Automatically send messages to those customers who haven't collected/redeemed rewards recently to re-engage them with your business.    
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- End about Area --> */}

            <section id="video" className="about-video-area section-gap">
                <div className="vdo-bg">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12 about-video-right justify-content-center align-items-center d-flex relative">
                                <div className="overlay overlay-bg"></div>
                                <a onClick={() => setShowVideo(true)} className="play-btn">
                                    <img className="img-fluid mx-auto" src={playbtn}alt="" />
                                </a>
                                {showVideo && (
                                    <div className='video-container'>
                                        <div className='video-bg' onClick={() => setShowVideo(false)}></div>
                                        <video controls autoplay muted>                    
                                                <source src="https://res.cloudinary.com/siimpl/video/upload/v1638571952/locale/578082f0-548b-11ec-b573-7b3d1b544dc9.mp4" type="video/mp4" />
                                                Your browser does not support the video tag.
                                        </video>
                                    </div>
                                )}								
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

const AppStoreLinks = () => (
    <div className="download-button d-flex flex-row flex-wrap justify-content-start">
        <div className="buttons flex-row d-flex">
            <i className="fa fa-apple" aria-hidden="true"></i>
            <div className="desc">
                <a href="#">
                    <p>
                        <span>Available</span> <br/>
                        on App Store
                    </p>
                </a>
            </div>
        </div>
        <div className="buttons dark flex-row d-flex">
            <i className="fa fa-android" aria-hidden="true"></i>
            <div className="desc">
                <a href="#">
                    <p>
                        <span>Available</span> <br/>
                        on Play Store
                    </p>
                </a>
            </div>
        </div>
    </div>
)

export default Home;
