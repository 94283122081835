import { createContext, useMemo, useState } from "react";

export const DataContext = createContext({ user: [] });

export default function DataProvider(props) {
    const [merchants, setMerchants] = useState([]);
    const [users, setUsers] = useState([]);
    const [points, setPoints] = useState([]);
    

    const providerValue = useMemo(() => ({ setMerchants, merchants, users, setUsers, points, setPoints }), [setMerchants, merchants, users, setUsers, points, setPoints]);

    return (
        <DataContext.Provider value={providerValue}>
            {props.children}
        </DataContext.Provider>
    )
}