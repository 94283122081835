
export default function AvailableList({ data }) {
    const arr = data ? JSON.parse(data) : [];
    if (arr.length > 0) {
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {arr.map((item, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', paddingHorizontal: 10, flexDirection: 'row', marginRight: 10 }}>
                    <p style={{ fontSize: 4, color: 'grey', marginHorizontal: 5 }}>{'\u2B24'}</p>
                    <p style={{ marginLeft: 10, color: 'grey' }}>
                      {item}
                    </p>
                  </div>
                ))}
            </div>
        )
    }

    return (
        <div />
    )
    
}