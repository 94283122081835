import { useState } from 'react';
import './Contact.scss'

function Contact() {
    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');
    const [confirmation, setConfirmation] = useState(null);

    const confirmationError = confirmation && confirmation.includes('error')

    const handleSubmit = (e) => {
        e.preventDefault();

        const email = {
            name,
            emailAddress,
            message
        }
        
        fetch('/contactmailer', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(email)
        })
            .then(() => {
                setConfirmation('We have received your request and will be in touch soon!')
            })
            .catch(() => {
                setConfirmation('There was an error submitting your request. Please try again later or email info@localeapp.co')
            })
    }

    return (
        <div>
            <section className="home-banner-area">
                <div className="container">
                    <div className="row fullscreen d-flex align-items-center justify-content-between">
                        <form id="contact-form" onSubmit={(e) => handleSubmit(e)} method="POST">
                            <div className="form-group">
                                <label htmlFor="name">Name</label>
                                <input type="text" onChange={(e) => setName(e.target.value)} value={name} className="form-control" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputEmail1">Email address</label>
                                <input type="email" onChange={(e) => setEmailAddress(e.target.value)} value={emailAddress} className="form-control" aria-describedby="emailHelp" />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message</label>
                                <textarea className="form-control" onChange={(e) => setMessage(e.target.value)} value={message} rows="3"></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={!name || !emailAddress || !message}>Submit</button>
                            {confirmation && (
                                <p className={`confirmation-message ${confirmationError && 'confirmation-error'}`}>{confirmation}</p>
                            )}

                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Contact;
