import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/analytics';

export type User = {
  uid: string,
  displayName: string,
  isFirstLogin: boolean,
  photoURL: string,
} | null;

type Token = string | null;

const firebaseConfig = {
  apiKey: "AIzaSyAql56UiXtY7wuHg7UkiH3Bo57ulhIeqo0",
  authDomain: "siimpl.firebaseapp.com",
  databaseURL: "https://siimpl.firebaseio.com",
  projectId: "siimpl",
  storageBucket: "siimpl.appspot.com",
  messagingSenderId: "633588781524",
  appId: "1:633588781524:web:bca4d0f85b7e9b32fb0641",
  measurementId: "G-PN1FF0Z7SH"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.analytics().logEvent('notification_received');

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const  db = firebase.firestore();

export async function getUserDocument(uid: string): Promise<User> {
  if (!uid) {
    return null;
  }
  
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get();
    const test = userDocument.data();
    return {  
      uid,
      displayName: '',
      isFirstLogin: false,
      photoURL: ''
    };
  } catch (error) {
    console.error("Error fetching user", error);
    return null;
  }
};

export async function getToken(): Promise<Token> {
  if (!auth.currentUser) {
    console.log('no current user')
    return null;
  }

  return auth.currentUser.getIdToken(true)
  .then(function(idToken) {
      return idToken;
  }).catch(function (error) {
    console.error(error);
    return null;
  });

}

export async function signOut(): Promise<string> {
  return auth.signOut().then(() =>  window.location.href = '/')
}