import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase";
import { FormControl, Form, Button } from 'react-bootstrap';

import './Auth.scss';
import bg from '../assets/home-banner-bg.jpg';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch(error => {
      setError("Error signing in with password and email!");
      console.error("Error signing in with password and email", error);
    });
  };

  const forgotPassword = () => {
    if (email === "") {
      setError("Enter email above and hit Forgot Password")
    } else {
      setError(`Reset email has been sent to ${email}`)
    }
    return auth.sendPasswordResetEmail(email)
  }

  return (
    <div className="auth_signin">
      <h1>Sign In</h1>
      <Form className="p-3" onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); signInWithEmailAndPasswordHandler(e, email, password) }}>
        <FormControl aria-label="Small" type="email" name="email" placeholder="Email Address..." value={email} onChange={(e) => setEmail(e.target.value)} aria-describedby="inputGroup-sizing-sm" />
        <FormControl aria-label="Small" type="password" placeholder="Password..." name="password" value={password} onChange={(e) => setPassword(e.target.value)} className="mb-2" aria-describedby="inputGroup-sizing-sm" />
        <Button variant="link" className="forgot_btn" onClick={() => forgotPassword()}>Forgot Password?</Button>
        {error && (
          <div className="mb-3 text-center error-msg" style={{ color: "#b73c3c" }}>{error}</div>
        )}
        <Button className="sign_in_btn" type="submit">SIGN IN</Button>
      </Form>

    </div>
  )
}

const Auth = () => {

  const navigate = useNavigate();

  useEffect(() => {
    auth.onAuthStateChanged(userAuth => {
      if (userAuth) {
        console.log(userAuth)
        if (window.location.pathname.toLowerCase() === "/login") {
          navigate("/admin")
        } else {
          navigate(window.location.pathname.toLowerCase() + window.location.search)
        }

      }
    });
  }, [])

  return (
    <div id="auth_screen" >
      <div className="bg" style={{ backgroundImage: `url(${bg})` }}></div>
      <div className="auth-container">
        <div className="login-option-container">
            <Login />
          <p className="agree">
            By creating an account you agree to our <Button variant="link" href="/Terms" target="_blank">Terms</Button> and <Button variant="link" href="/Privacy" target="_blank">Privacy Policy</Button>
          </p>
        </div>
      </div>

    </div>
  )
};

export default Auth;