import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { fetchAPI } from '../helpers/FetchAPI';
import Autocomplete from '../Components/AutoComplete';

import './LocaleFeatures.scss';

export default function LocaleFeatures({ locationId, close }) {
    const [error, setError] = useState(null);
    const [drinksAvailable, setDrinksAvailable] = useState([]);
    const [milkAvailable, setMilkAvailable] = useState([]);
    const [foodAvailable, setFoodAvailable] = useState([]);
    const [otherAvailable, setOtherAvailable] = useState([]);

    useEffect(() => {
        getLocationFeatures();
    }, [])
    
    const getLocationFeatures = () => {
        fetchAPI.get('/api/getLocationFeatures', {
            headers: {
                'merchantid' : locationId
            }
        })
        .then(data => {
            setDrinksAvailable(JSON.parse(data.AvailableDrink))
            setMilkAvailable(JSON.parse(data.AvailableMilk))
            setFoodAvailable(JSON.parse(data.AvailableFood))
            setOtherAvailable(JSON.parse(data.AvailableOther))
        }).catch(err => {
            console.log(err);
        });
    };

    const removeItem = (type, item) => {

        switch (type) {
            case "drink": setDrinksAvailable(prevState => prevState.filter(el => el !== item));
                break;
        
            case "milk": setMilkAvailable(prevState => prevState.filter(el => el !== item));
                break;
        
            case "food": setFoodAvailable(prevState => prevState.filter(el => el !== item));
                break;
        
            case "other": setOtherAvailable(prevState => prevState.filter(el => el !== item));
                break;

            default: setError('could not delete item');
        };
    };
        
    const saveFeatures = () => {

        const data = {
            merchantId: locationId,
            availableDrink: JSON.stringify(drinksAvailable),
            availableMilk: JSON.stringify(milkAvailable),
            availableFood: JSON.stringify(foodAvailable),
            availableOther: JSON.stringify(otherAvailable),
        };

        fetchAPI.post('/api/updateLocationFeatures', {
            body: data
        })
            .then(() => {
                close();
            }).catch(err => {
                console.log(err);
            });
    };

    return (
        <div id="locale-features">
            <em>{error}</em>
            <div className='d-flex'>
                <div className='feature-option'>
                    <h5 className='feature-title'>Drinks available</h5>
                    <div className='feature-container'>
                        <Autocomplete suggestions={[
                            "Brewed Coffee",
                            "Iced Coffee",
                            "Lattes",
                            "Tea",
                            "Hot Chocolate",
                            "Cold Brew",
                            "Quenchers",
                            "Lemonade"
                        ]}
                            onSelect={(e) => setDrinksAvailable([...drinksAvailable, e])}
                        />

                        <ul className='feature-list'>
                            {drinksAvailable.map((item, index) =>
                                <li key={index}>
                                    {item}
                                    <Button variant='link' onClick={() => removeItem('drink', item)}>x</Button>
                                </li>
                            )}
                        </ul>
                    </div>
                    
                </div>
                <div className='feature-option'>
                    <h5 className='feature-title'>Milk available</h5>
                    <div className='feature-container'>
                        <Autocomplete suggestions={[
                            "Diary",
                            "Almond",
                            "Coconut",
                            "Oat",
                            "Soy"
                        ]}
                            onSelect={(e) => setMilkAvailable([...milkAvailable, e])}
                        />

                        <ul className='feature-list'>
                            {milkAvailable.map((item, index) =>
                                <li key={index}>
                                    {item}
                                    <Button variant='link' onClick={() => removeItem('milk', item)}>x</Button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className='feature-option'>
                    <h5 className='feature-title'>Food available</h5>
                    <div className='feature-container'>
                        <Autocomplete suggestions={[
                            "Breakfast",
                            "Bakery",
                            "Lunch",
                            "Snacks & Sweets",
                            "Oatmeal & Yoghurt"
                        ]}
                            onSelect={(e) => setFoodAvailable([...foodAvailable, e])}
                        />

                        <ul className='feature-list'>
                            {foodAvailable.map((item, index) =>
                                <li key={index}>
                                    {item}
                                    <Button variant='link' onClick={() => removeItem('food', item)}>x</Button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                <div className='feature-option'>
                    <h5 className='feature-title'>Other</h5>
                    <div className='feature-container'>
                        <Autocomplete suggestions={[
                            "Dine In",
                            "Wi-Fi"
                        ]}
                            onSelect={(e) => setOtherAvailable([...otherAvailable, e])}
                        />

                        <ul className='feature-list'>
                            {otherAvailable.map((item, index) =>
                                <li key={index}>
                                    {item}
                                    <Button variant='link' onClick={() => removeItem('other', item)}>x</Button>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            <div className='text-end pt-2'>
                <Button onClick={() => saveFeatures()}>Save</Button>
            </div>
        </div>
    )
}