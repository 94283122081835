import { useContext, useEffect, useState } from 'react';
import { Table, Dropdown } from 'react-bootstrap';
import { fetchAPI } from '../helpers/FetchAPI';
import { DataContext } from '../Providers/DataProvider';

const PointsTable = () => {
  const { points, setPoints } = useContext(DataContext);
  const [searchQuery, setSearchQuery] = useState('');  

  useEffect(() => {
    getPoints();
  }, [])
  
  const getPoints = () => {
    if (points.length > 0) {
        setPoints(points);
    } else {
      fetchAPI.get('/api/points')
        .then(points => {
            setPoints(points);
        }).catch(err => {
          console.log(err);
        });
    }
  };
  
  // const filteredUsers = points.filter(el => el.DisplayName.toLowerCase().includes(searchQuery.toLowerCase()) || el.UserEmail.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <div>
        {/* <input placeholder="Search points..." type="text" onChange={(e) => setSearchQuery(e.target.value)} />           */}

        <Table responsive>
          <thead>
            <tr>
                <th>#</th>
                <th></th>
                <th>Points</th>
                <th>Points type</th>
                <th>Merchant</th>
                <th>User</th>
                <th>Date</th>
                <th></th>
            </tr>
          </thead>
          <tbody>
          {points.map((item, index) =>
            <tr key={index}>
              <td>{item.Id}</td>
              <td>
                <div style={{ width: '40px', height: '40px', borderRadius: '40px', overflow: 'hidden' }}>
                  <img src={item.merchant_image} alt='merchant storefront' style={{ objectFit: 'cover', width: '100%', height: '100%' }}/>
                </div>
              </td>
              <td>{item.points_collected}</td>
              <td>{item.order_type}</td>
                  <td>{item.merchant_name}</td>
                  <td>{item.DisplayName}</td>
              <td>{new Date(item.date_created).toDateString()}</td>
              <td>
              <Dropdown>
                <Dropdown.Toggle variant="link" className="dropdown-basic">
                  <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item >Edit</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </td>
            </tr>
          )}
          </tbody>
        </Table>
    </div>
  )
}

export { PointsTable }