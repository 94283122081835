import React, {useCallback, useState} from 'react'
import { useDropzone } from 'react-dropzone'
import { fetchAPI } from '../helpers/FetchAPI';

import './LocaleImageEditor.scss';

function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      console.log(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
 }
    
export const LocaleImageEditor = ({ location, close }) => {
    const [image, setImage] = useState(null);

    const onDrop = useCallback(acceptedFiles => {
        // Do something with the files
        console.log(acceptedFiles)
        setImage(getBase64(acceptedFiles[0]));
    }, [])

    const updateImage = () => {

        const data = {
            merchantId: location.Id,
            image_url: image
        }
        
        fetchAPI.post('/api/updateLocationImage', {
            body: data
        })
			.then(() => {
                location.image_url = image;
                close();
			}).catch(err => {
				console.log(err);
			});
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
    
    return (
        <div className='locale-image-editor'>
            <h5>Select file</h5>
            <div className='drop-zone' {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
            <h5>Image Url</h5>
            <input className='w-100' placeholder='Image url' onChange={(e) => setImage(e.target.value)} />

            <button onClick={() => updateImage()}>Save</button>
        </div>
    )
}