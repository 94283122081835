import { useContext, useEffect, useState } from 'react';
import { fetchAPI } from '../helpers/FetchAPI';
import { Table, Dropdown, Button } from 'react-bootstrap';
import Rodal from 'rodal';

import { DataContext } from '../Providers/DataProvider';
import { LocaleImageEditor } from '../Admin/LocaleImageEditor';
import { LocaleEditor } from '../Admin/LocaleEditor';
import AvailableList from '../Components/AvailableList';
// import { Table } from '../Components/Table/Table';

import './Admin.scss';

function getAddress(location) {
  if (!location) {
    return '';
  }

  const addressJSON = JSON.parse(location);
  return addressJSON.display_address;
}


const emptyLocation = {
  Id: -1,
  name: 'Locale',
  image_url: 'https://fastly.4sqi.net/img/general/600x600/1589524_XTdim4JBbyCNcNEGOvgpEP2Iz0LM4XQL949_vd46zpc.jpg',
  rating: 0,
  price: 1,
  location: '',
  has_parking: true,
  phone: '11111',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec dui odio. Duis at dapibus urna, sed pretium purus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Integer semper imperdiet nunc, id mollis ante commodo nec. Cras commodo tellus feugiat tellus placerat, at maximus erat dapibus. Maecenas accumsan sodales tellus, ut pellentesque augue tincidunt a. Praesent feugiat augue in augue tristique, non posuere ex dignissim. Mauris vel lorem sit amet diam lacinia porttitor sit amet ut quam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla euismod risus lectus, tempus finibus orci viverra id.'
}

function Admin() {
  const { merchants, setMerchants } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [editImageModal, setEditImageModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [merchantOfferModal, setMerchantOfferModal] = useState(false);
  const [merchantAddOfferModal, setMerchantAddOfferModal] = useState(false);
  
  
  const [locations, setLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedLocation, setSelectedLocation] = useState(emptyLocation);

  const filteredLocations = searchQuery.length === 0 ? locations : locations.filter(el => el.name.toLowerCase().includes(searchQuery.toLowerCase()))

  useEffect(() => {
    getlocations();
  }, []);

  const getlocations = () => {
    if (merchants.length > 0) {
      setLocations(merchants);
      setIsLoading(false);
    } else {
      fetchAPI.get('/api/getLocations')
        .then(locations => {
          setMerchants(locations);
          setLocations(locations);
          setIsLoading(false);
			}).catch(err => {
				console.log(err);
			});
    } 
  }

  if (isLoading) {
    return (
      <div>Loading...</div>
    );
  };

  const merhcantTable = [
    {
      text: '#',
      dataField: 'Id'
    },
    {
      text: 'Merchant Name',
      dataField: 'name'
    },
    {
      text: 'Address',
      dataField: 'location'
    }
  ]

  return (
    <div className='admin-page'>
      
      <div>
          <input placeholder="Search coffee shops" type="text" onChange={(e) => setSearchQuery(e.target.value)} />         

          {/* <Table columns={merhcantTable} data={locations} /> */}
          <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Merchant Name</th>
                <th>Address</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {filteredLocations.map((item, index) =>
              <tr key={index}>
                <td>{item.Id}</td>
                <td>
                  <div style={{ width: '40px', height: '40px', borderRadius: '40px', overflow: 'hidden' }}>
                    <img src={item.image_url.replace('o.jpg','120s.jpg')} alt='merchant storefront' style={{ objectFit: 'cover', width: '40px', height: '40px' }} loading="lazy"/>
                  </div>
                </td>
                <td>{item.name}</td>
                <td>{getAddress(item.location)}</td>
                <td>
                <Dropdown>
                  <Dropdown.Toggle variant="link" className="dropdown-basic">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1" onClick={() => { setEditModal(true); setSelectedLocation(item) }}>Edit merchant</Dropdown.Item>
                      <Dropdown.Item href="#/action-2" onClick={() => { setPreviewModal(true); setSelectedLocation(item); }}>Show preview</Dropdown.Item>
                      <Dropdown.Item href="#/action-2" onClick={() => { setEditImageModal(true); setSelectedLocation(item); }}>Edit merchant image</Dropdown.Item>                      
                      <Dropdown.Item href="#/action-3" onClick={() => { setMerchantOfferModal(true); setSelectedLocation(item); }}>View Offers</Dropdown.Item>
                      <Dropdown.Item href="#/action-3" onClick={() => { setMerchantAddOfferModal(true); setSelectedLocation(item); }}>Add new offer</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
      </div>
      

      <Rodal visible={previewModal} onClose={() => setPreviewModal(false)} showCloseButton={false}>
        <div className='admin-page__location-editor'>
          <div className='iphone-wrapper'>
            <div className='iphone-wrapper__location-image' style={{ backgroundImage: `url(${selectedLocation.image_url})` }} >
            </div>
            <div className='iphone-wrapper__content'>
              <div>
                <h4 className='iphone-wrapper__title'>{selectedLocation.name}</h4>
              </div>

              <div className='d-flex'>
                <div className='iphone-wrapper__option'>
                  <div className='option-content'>
                    <div>
                      <span style={{ fontSize: 35, fontWeight: '700', textAlign: 'center', color: '#01a1dd' }}>{selectedLocation.rating}</span>
                      <span style={{ fontSize: 12 }}>/5</span>
                    </div>
                    <div>10 ratings</div>
                  </div>              
                </div>
                <div className='iphone-wrapper__option'>
                  <div className='option-content'>
                    <div style={{ fontSize: 23, fontWeight: '700', color: '#634CC2', textAlign: 'center' }}>{selectedLocation.price}</div>
                    <div >price</div>
                  </div>
                </div>
                <div className='iphone-wrapper__option'>
                  <div style={{ fontSize: 35, fontWeight: '700', color: '#634CC2', textAlign: 'center' }}>xx</div>
                  <br />
                  m away
                </div>
              </div>

              <div>
                <div className='d-flex mb-2'>
                  <div style={{ width: 25, justifyContent: 'center', alignItems: 'center', backgroundColor: '#c9dae9', paddingLeft: 5, borderRadius: 5, marginRight: 10 }}>
                    <svg viewBox="0 0 24 24" width="15" height="15" stroke="#01a1dd" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  </div>
                  <div style={{ fontSize: 15, fontWeight: '300', color: '#6e6b6b'  }}>Location</div>
                </div>

                <div className='d-flex mb-2'>
                  <div style={{ width: 25, justifyContent: 'center', alignItems: 'center', backgroundColor: '#c9dae9', paddingLeft: 5, borderRadius: 5, marginRight: 10 }}>
                    <svg viewBox="0 0 24 24" width="15" height="15" stroke="#01a1dd" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  </div>
                  <div style={{ fontSize: 15, fontWeight: '300', color: '#6e6b6b'  }}>{selectedLocation.has_parking ? 'Has parking' : 'No parking'}</div>
                </div>

                <div className='d-flex mb-2'>
                  <div style={{ width: 25, justifyContent: 'center', alignItems: 'center', backgroundColor: '#c9dae9', paddingLeft: 5, borderRadius: 5, marginRight: 10 }}>
                    <svg viewBox="0 0 24 24" width="15" height="15" stroke="#01a1dd" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  </div>
                  <div style={{ fontSize: 15, fontWeight: '300', color: '#6e6b6b'  }}>Get Directions</div>
                </div>
                
                <div className='d-flex mb-2'>
                  <div style={{ width: 25, justifyContent: 'center', alignItems: 'center', backgroundColor: '#c9dae9', paddingLeft: 5, borderRadius: 5, marginRight: 10 }}>
                    <svg viewBox="0 0 24 24" width="15" height="15" stroke="#01a1dd" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  </div>
                  <div style={{ fontSize: 15, fontWeight: '300', color: '#6e6b6b'  }}>Contact</div>
                </div>

                <div className='d-flex mb-2'>
                  <div style={{ width: 25, justifyContent: 'center', alignItems: 'center', backgroundColor: '#c9dae9', paddingLeft: 5, borderRadius: 5, marginRight: 10 }}>
                    <svg viewBox="0 0 24 24" width="15" height="15" stroke="#01a1dd" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                  </div>
                  <div style={{ fontSize: 15, fontWeight: '300', color: '#6e6b6b' }}>Open today: 07:30 - 18:00</div>
                </div>

              </div>

              <div style={{ height: 10, width: '100%', backgroundColor: '#ededed', margin: '20px 0'}} />

              <div>
                <h5>In a few words</h5>
                <p className='iphone-wrapper__description'>
                  {selectedLocation.description}
                </p>
              </div>

              <div style={{ height: 10, width: '100%', backgroundColor: '#ededed', margin: '20px 0'}} />

              <div>
                <h5 className='mb-3'>What we are know for</h5>
                <div className='iphone-wrapper__photo-container'>
                  {[1, 2, 3, 4, 5, 6].map((item, index) => 
                    <div key={index} className='iphone-wrapper__photo-wrapper'>
                        <img alt='cofee shop bests' src={selectedLocation.image_url} />
                    </div>
                  )}
                </div>
              </div>

              <div style={{ height: 10, width: '100%', backgroundColor: '#ededed', margin: '20px 0'}} />

              <div className='iphone-wrapper__location-available'>
                <h5>Drinks available</h5>
                <AvailableList data={selectedLocation.AvailableDrink} />                
              </div>

              <div className='iphone-wrapper__location-available'>
                <h5>Milk available</h5>
                <AvailableList data={selectedLocation.AvailableMilk} /> 
              </div>

              <div className='iphone-wrapper__location-available'>
                <h5>Food available</h5>
                <AvailableList data={selectedLocation.AvailableFood} /> 
              </div>

              <div className='iphone-wrapper__location-available'>
                <h5>Other</h5>
                <AvailableList data={selectedLocation.AvailableOther} /> 
              </div>

              <div style={{ height: 10, width: '100%', backgroundColor: '#ededed', margin: '20px 0 200px 0'}} />
            </div>
          </div>
        </div>
      </Rodal>

      <Rodal visible={editImageModal} onClose={() => setEditImageModal(false)}>
        {editImageModal && (
          <LocaleImageEditor location={selectedLocation} close={() => setEditImageModal(false)}  />
        )}
      </Rodal>

      <Rodal visible={editModal} onClose={() => setEditModal(false)}>
        {editModal && (
          <LocaleEditor location={selectedLocation} close={() => setEditModal(false)}  />
        )}
      </Rodal>

      <Rodal visible={merchantAddOfferModal} onClose={() => setMerchantAddOfferModal(false)}>
        {merchantAddOfferModal && (
          <AddOffer location={selectedLocation} />
        )}
      </Rodal>

      <Rodal visible={merchantOfferModal} onClose={() => setMerchantOfferModal(false)}>
        {merchantOfferModal && (
          <Offer location={selectedLocation} />
        )}
      </Rodal>

    </div>
  );
}

export const AddOffer = ({ location }) => {
  const [offerTitle, setOfferTitle] = useState('');
  const [offerDescription, setOfferDescription] = useState('');
  
  const testOffer = () => {

    const data = {
        merchantId: location.Id,
        title: offerTitle,
        description: offerDescription
    }
    fetchAPI.post('/api/addOffers', {
        body: data
    })
  .then((result) => {
  }).catch(err => {
    console.log(err);
  });
  }
  
  return (
    <div className='offer-container'>
      <input placeholder='Offer title' value={offerTitle} onChange={(e) => setOfferTitle(e.target.value)} />
      <input placeholder='Offer description' value={offerDescription} onChange={(e) => setOfferDescription(e.target.value)} />
      <Button onClick={() => testOffer()} disabled={!offerTitle || !offerDescription}>Test Offer</Button>
    </div>
  )
}

export const Offer = ({ location }) => {
  const [offers, setOffers] = useState([]);

  useEffect(() => {
    getOffers();
  }, []);
  
  const getOffers = () => {
    fetchAPI.get(`/api/getOffers/${location.Id}`)
      .then((result) => {
        setOffers(result);
      }).catch(err => {
        console.log(err);
      });
  };

  const ExpireOffer = (offer) => {
    fetchAPI.post(`/api/expireOffer/${offer.Id}`)
    .then((result) => {
      setOffers(prevState => prevState.map(el => el === offer ? { ...offer, isActive: 0 } : el));
    }).catch(err => {
      console.log(err);
    });
  }

  const DeleteOffer = (offer) => {
    fetchAPI.post(`/api/deleteOffer/${offer.Id}`)
    .then((result) => {
      setOffers(prevState => prevState.filter(el => el !== offer));
    }).catch(err => {
      console.log(err);
    });
  }
  
  return (
    <div className='offer-container'>
      <Table responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Uses per user</th>
                <th>Uses</th>
                <th>Is Active</th>
                <th>Is Expired</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {offers.map((item, index) =>
              <tr key={index}>
                <td>{item.Id}</td>
                <td>{item.Title}</td>
                <td>{item.Description}</td>
                <td>{item.numberOfUsesPerUser}</td>
                <td>{item.numberOfUses}</td>
                <td>{item.isActive}</td>
                <td>
                  {/* // TODO: calculate if offer has expired  */}
                </td>
                <td>
                <Dropdown>
                  <Dropdown.Toggle variant="link" className="dropdown-basic">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="12" cy="12" r="1"></circle><circle cx="12" cy="5" r="1"></circle><circle cx="12" cy="19" r="1"></circle></svg>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                      <Dropdown.Item onClick={() => {  }}>Edit Offer</Dropdown.Item>
                      <Dropdown.Item onClick={() => ExpireOffer(item) }>Expire Offer</Dropdown.Item>
                      <Dropdown.Item onClick={() => DeleteOffer(item)}>Delete Offer</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                </td>
              </tr>
            )}
            </tbody>
          </Table>
    </div>
  )
}

export default Admin;
