import UserProvider from "./Auth/UserProvider";
import Pages from "./Pages";

import './App.scss';
import 'rodal/lib/rodal.css';

const App = (): JSX.Element => {
  return (
    <UserProvider>
      <div id='locale'>
          <Pages />
      </div>
    </UserProvider>
  );
}

export default App;
